import React from "react";
import classes from "./StartOfStory.module.css";
import future from '../images/future.jpg';
import founders from '../images/founders.jpeg';
import duifke from '../images/duifke.jpg';

const StartOfStory = () => {

    return (
        <div className={classes.card}>
      <div>
          <h1>The beginning of an era</h1>
          <h2>Where it all began</h2>
          <p>In 1993 Syntris Bvba was founded by Hildegarde and Ludovicious. Located in the area of Zemst, right outside the thriving city of Brussels.</p>
          <img src={founders} alt="Founders" className="rounded mx-auto d-block img-fluid" />
          <p>The water was clear, the grass was green. The European Economic evolution led by the introduction of the Euro currency made for a thriving economy, the invention of the computer and the world wide web drastically improved the lives of mail pigeons around the world</p>
          <h3>What started off as a consultancy agency quickly grew out to an imperium.</h3>
        <p>At the start Syntris was specializing in retraining mail pigeons to perform simple computer tasks. This worked out great and before the world knew what AI was there was no more need for it as pigeons allowed work to continue when humanity was a sleep.</p>
        <p>Until... The invention of the unpaid intern came along. Pigeons needed to be fed whereas interns could be told that this was just the beginning of their career and that they should see it as a learning opportunity. Which sounds ridiculous as it meant interns having to pay for their own food and working for free. But they did it anyway.</p>
        <h3>Supply and demand</h3>
        <p>The economy has shifted. Hildegarde and Ludovicious noticed it and in true management consultancy fashion came up with a plan. What if, interns can eat the pigeons. Pigeons have a good life, interns do the work and in the end interns eat the pigeons. On the weekend the middle managers of the interns can shoot the pigeons. Everybody happy right?</p>
        <p>The plan was a world wide success. The life expectancy of pigeons increased as their stress levels reduced. Managers had a fun time on the weekends and the interns were told this was just the beginning of their career and seemingly bought it. The word 'exploitation' didn't exist yet.</p>
        <p>Pigeon culture expanded immensely, new hobbies were explored. Some pigeons got into arts, music, architecture, and some even started getting interested in photography. This was a true turning point, this is where the true technological evolution of Syntris began</p>
        <img src={duifke} alt="Pigeon" className="rounded mx-auto d-block img-fluid"/>
        <h3>Let's call it birds eye view!</h3>
        <p>In those days maps were still drawn by humans traveling by horse and keeping track of their movements. Now that pigeons started taking pictures for fun. Ludovicious had an new plan that would change the world as we knew it.</p>
        <p>Why don't we take these pictures, glue them together and make a view as if a bird would see it. Wouldn't that be nice? Maybe digitize it so you can use it on a mobile battery powered screen with and lots of lag. Later the first GPS was born.</p>
        <h3>The future was bright.</h3>
        <p>Images of pigeons had to be analysed. This was done by humans up until Syntris created AI to analyse Pigeon photo's to recognize objects and areas. Identifying things like, industrial estates, homes, trees, pools, pedestrians... This data is used in a wide range of industries. Maritime, Defence but most importantly autonomous driving.</p>
        <h3>Where we are now</h3>
        <p>This was just a long joke, we're a management consultancy agency and one of our engineering friends had 5 minutes of fun...</p>
        <p>But there is a takeaway. Management consultants need to pivot. Even when we have complete freedom to make up a funny story, we pivot. Need our help with your next big pivot?</p>
        <h3>We provide services in 3 main regions:</h3>
        <ul>
            <li>Management consultancy including all sub categories: change management, leadership coaching, strategy...</li>
            <li>Creative: writing, branding, arts...</li>
            <li>Technology: hands on networking stuff for SMB, edge side security and performance, serverless computing on a budget...</li>
        </ul>
        <h3>Future</h3>
        <p>We'd be lying if we said we knew for certain what the future would bring. However, we know how to play chess. We think ahead, we make a plan for each possible outcome, we're here to make things happen</p>
        <img src={future} alt="The future" className="rounded mx-auto d-block img-fluid" />
        <h1 className="text-center">We'll wrap our head around it.</h1>
          </div>
        </div>
    )
};

export default StartOfStory;