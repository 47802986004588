import React from 'react';
import classes from './Header.module.css';
import logo from '../images/syntrislogo.png';

const Header = () => {
    return (
        <nav className={classes.navStickyWhite}>
            <div className='navbar navbar-expand-lg navbar-light container container--large'>
                    <div className='navbar-brand'>
                        <img src={logo} alt='Syntris logo'/> </div>
            </div>
        </nav>
    )

};

export default Header;
