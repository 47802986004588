import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route, 
  Link
} from "react-router-dom";
import StartOfStory from './Blocks/StartOfStory';
import Home from './Blocks/Home';
import Header from './UI/Header';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/easteregg" element={<StartOfStory />} />
      </Routes>
      </Router>
  );
}


export default App;
