import React from "react";
import classes from "./Home.module.css";

const StartOfStory = () => {

    return (
        <div className={classes.card}>
      <h1 className={classes.BC}>Business consultancy</h1>
      <p>Over 15 years of experience in management consultancy, change management, commercial leadership, coaching.</p>
      <h1 className={classes.CW}>Copywriting</h1>
      <p>Translations, new copy writing...</p>
      <h1 className={classes.TECH}>Technology services</h1>
      <p></p>
        </div>
    )
};

export default StartOfStory;